<template>
  <div>
    <b-card class="mt-0 px-2">
      <b-row
        class="mb-3 border-bottom"
      >
        <b-col cols="6">
          <div>
            <feather-icon
              icon="ListIcon"
              size="20"
              class="text-primary"
            /> <span
              class="text-primary"
              style="font-size:17px"
            ><strong>LISTA DE INGRESOS - FARMACIA</strong></span>
          </div>
        </b-col>
        <b-col
          cols="6"
          class="mb-1"
        >
          <b-button
            size="sm"
            variant="outline-info"
            class="float-right"
            @click="$router.push({ name: 'farmacia.ingresos.nuevaCompra' })"
          >
            <feather-icon
              icon="PlusCircleIcon"
              size="15"
            />
            Nuevo ingreso
          </b-button>
        </b-col>
      </b-row>
      <div class="m-2">
        <!-- <b-row class="mb-2">
          <b-button
            @click="$router.push({ name: 'farmacia.ingresos.nuevaCompra' })"
          >
            Agregar nueva compra
          </b-button>
        </b-row> -->
        <b-row class="m-0">
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start m-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="container float-right">
              <b-form-input
                v-model="filter"
                size="sm"
                type="search"
                class="d-inline-block"
                placeholder="Buscar..."
                debounce="500"
              />
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table
        id="my-table"
        ref="refTablaIngresosCompra"
        class="position-relative"
        show-empty
        head-variant="light"
        hover
        small
        responsive
        :busy.sync="isBusy"
        :items="myProvider"
        :fields="fields"
        :current-page="currentPage"
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong> cargando...</strong>
          </div>
        </template>
        <template #cell(codigo)="row">
          <span @click="verDetallesCompra(row.item)">
            <feather-icon
              icon="FolderPlusIcon"
              size="20"
              class="align-middle text-primary"
              style="margin-right:5px; margin-bottom:5px"
            />
            <span class="text-primary"><strong>{{ row.item.codigo }}</strong></span>
          </span>

        </template>
        <template #cell(fecha_compra)="row">
          <span class="text-primary">{{ row.item.fecha_compra }}</span>
        </template>
        <template #cell(fecha_entrega)="row">
          <span class="text-primary">{{ row.item.fecha_entrega }}</span>
        </template>
        <template #cell(total)="row">
          <b-badge
            variant="light-success"
            class=""
          >
            <span
              class="text-primary"
              style="font-size:14px"
            >{{ row.item.lotes.length }} </span>
          </b-badge>

        </template>
        <!-- column: acciones -->
        <template #cell(acciones)="row">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="17"
                class="align-middle text-primary"
              />
            </template>
            <b-dropdown-item
              @click="verDetallesCompra(row.item)"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">ver productos</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando registros del {{ from }} al {{ to }} de un total de {{ totalRows }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>
    </b-card>
    <ModalIngresosMedicamento />
  </div>
</template>

<script>
import {
  BCard, BTable, BSpinner, BRow, BCol, BPagination, BFormInput, BDropdown,
  BDropdownItem, BButton, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import ModalIngresosMedicamento from '@/views/salud/farmacia/ingresos-list/ModalIngresosMedicamento.vue'
import ingresosListStoreModule from './ingresosListStoreModule'

export default {
  components: {
    BCard,
    BTable,
    BSpinner,
    vSelect,
    BRow,
    BCol,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BBadge,
    // BMedia,
    // BAvatar,
    // BProgress,
    // BCardText,

    // externs
    ModalIngresosMedicamento,
  },
  data() {
    return {
      isBusy: false,
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 50],
      sortBy: 'id',
      sortDesc: false,
      // sortDirection: 'desc',
      filter: '',
      filterOn: [],

    }
  },
  methods: {
    actualizarTabla() {
      // this.$refs.refTablaUsuarios.refresh()
    },
    verDetallesCompra(item) {
      this.$emit('cargarDetallesCompra', item)
      this.$bvModal.show('modal_detalles_ingresos_medicamento')
    },
    async myProvider(ctx) {
      // console.log('CTX: ', ctx)
      const promise = store.dispatch('ingresos-list-store/getIngresosMedicamentos', { queryParams: ctx })

      // Must return a promise that resolves to an array of items
      return promise.then(response => {
        // console.log('responseGetIngresosMedicamentos: ', response)
        const {
          data, total, from, to,
        } = response.data
        // Pluck the array of items off our axios response
        const items = data
        this.totalRows = total || 0
        // this.from = meta.pagination.count
        this.from = from || 0
        this.to = to || 0
        /*
        items.forEach((value, index) => {
          items[index].fecha_venc_dias = this.fecha_menor_en_dias(value.lotes_disponibles)
        }) */

        // Must return an array of items or an empty array if an error occurred
        return items || []
      })
    },
  },
  setup() {
    const MEDICAM_INGRESOS_APP_STORE_MODULE_NAME = 'ingresos-list-store'

    // Register module
    if (!store.hasModule(MEDICAM_INGRESOS_APP_STORE_MODULE_NAME)) store.registerModule(MEDICAM_INGRESOS_APP_STORE_MODULE_NAME, ingresosListStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEDICAM_INGRESOS_APP_STORE_MODULE_NAME)) store.unregisterModule(MEDICAM_INGRESOS_APP_STORE_MODULE_NAME)
    })

    const fields = [
      { key: 'proveedor_id', label: 'PROVEEDOR', sortable: true },
      { key: 'codigo', label: 'NOMBRE COMPRA', sortable: true },
      {
        key: 'fecha_compra', label: 'FECHA COMPRA', sortable: true, class: 'text-center',
      },
      {
        key: 'fecha_entrega', label: 'FECHA ENTREGA', sortable: true, class: 'text-center',
      },
      {
        key: 'total', label: ' TOTAL DE PRODUCTOS', sortable: true, class: 'text-center',
      },
      { key: 'acciones' },
    ]
    return {
      // Filter
      avatarText,
      fields,
      ingresosListStoreModule,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
