<template>
  <div>
    <b-modal
      id="modal_detalles_ingresos_medicamento"
      ok-only
      size="xl"
      class="p-5"
      title="Detalles compra"
      no-close-on-esc
      no-close-on-backdrop

      modal-class="modal-success"
    >

      <b-row class="mt-1">
        <b-col
          cols="4"
          class="text-center"
        >
          <b-card class="mx-0 mb-1 border rounded">
            <b-badge
              variant="light-info"
              class="px-2 mb-1"
            >
              <feather-icon
                icon="UserIcon"
                class="text-info"
              /> <span class="font-weight-bold text-info text-align-center"><strong>NOMBRE DE LA COMPRA:</strong> </span><br>
            </b-badge><br>
            <span class="text-align-center">{{ itemIngreso.codigo }}</span>
          </b-card>
        </b-col>
        <b-col
          cols="4"
          class="text-center"
        >
          <b-card class="mx-0 mb-1 border rounded">
            <b-badge
              variant="light-warning"
              class="px-2 mb-1"
            >
              <feather-icon
                icon="SmileIcon"
                class="text-warning"
              /> <span class="font-weight-bold text-warning text-align-center"><strong>FECHA COMPRA:</strong> </span><br>
            </b-badge><br>
            <span class="text-align-center ">{{ itemIngreso.fecha_compra }}</span>
          </b-card>
        </b-col>
        <b-col
          cols="4"
          class="text-center"
        >
          <b-card class="mx-0 mb-1 border rounded">
            <b-badge
              variant="light-success"
              class="px-2 mb-1"
            >
              <feather-icon
                icon="CalendarIcon"
                class="text-success"
              /> <span class="font-weight-bold text-success text-align-center"><strong> FECHA DE INGRESO:</strong></span><br>
            </b-badge><br>
            <span class="text-align-center">{{ itemIngreso.fecha_entrega }}</span>
          </b-card>
        </b-col>
      </b-row>
      <b-card class="mt-0">
        <div class="mb-1 ml-2 pb-2 border-bottom">
          <feather-icon
            icon="ListIcon"
            class="text-primary mr-1"
            size="18"
          />
          <span class="text-primary font-weight-bold mb-1"><strong>PRODUCTOS QUE INGRESARON</strong></span>
        </div>
        <div class="mt-1 ml-2 mr-1">
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Mostrar</label>
              <v-select
                v-model="perPage"
                :options="pageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>registros</label>
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="filter"
                  size="sm"
                  type="search"
                  class="d-inline-block mr-1"
                  placeholder="Buscar..."
                  debounce="500"
                />
              </div>
            </b-col>
          </b-row>

        </div>
        <b-card class="mt-0">

          <b-table
            id="my-table"
            ref="refTablaLotesDeCompra"
            class="position-relative"
            show-empty
            head-variant="light"
            hover
            small
            responsive
            :busy.sync="isBusy"
            :items="myProvider"
            :fields="fields_listaLotes"
            :current-page="currentPage"
            :empty-text="'No hay registros para mostrar'"
            :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle" />
                <strong> cargando...</strong>
              </div>
            </template>

            <template #cell(nombre_medicamento)="row">
              <b-media vertical-align="center">
                <template #aside>
                  <feather-icon
                    icon="CheckIcon"
                    size="18"
                    class="align-middle text-primary "
                  />
                </template>
                <b-link
                  class="text-primary font-weight-bold d-block text-nowrap"
                >
                  {{ row.item.producto.nombre_corto }}
                </b-link>
                <small> {{ row.item.producto.forma.nombre_simplificado }} | {{ row.item.producto.presentacion.nombre }}</small>
              </b-media>
            </template>

            <template #cell(cantidad_compra)="row">
              <span class="text-primary"> <strong>{{ row.item.cantidad_compra }}</strong></span>
            </template>

            <template #cell(cantidad_disponible)="row">
              <span :class="row.item.cantidad_compra === row.item.cantidad_disponible ? 'text-success' : 'text-danger'"> <strong>{{ row.item.cantidad_disponible }}</strong></span>
            </template>

            <template #cell(fecha_vencimiento)="row">
              <span class="text-primary"> <strong>{{ row.item.fecha_vencimiento }}</strong></span>
            </template>

            <!-- column: acciones -->
            <template #cell(acciones)="row">

              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                v-b-tooltip.hover.v-danger
                :title="row.item.cantidad_compra === row.item.cantidad_disponible ? 'eliminar' : 'No puede ser eliminado debido a que la cant. disponible es menor a la cant. compra'"
                variant="outline-danger"
                class="btn-icon rounded-circle"
                :disabled="row.item.cantidad_compra === row.item.cantidad_disponible ? false : true"
                @click="eliminarLote(row.item)"
              >
                <feather-icon
                  :icon="row.item.cantidad_compra === row.item.cantidad_disponible ? 'Trash2Icon' : 'MinusIcon'"
                />
              </b-button>

            </template>
          </b-table>

          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">Mostrando registros del {{ from }} al {{ to }} de un total de {{ totalRows }} registros</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>
            </b-row>
          </div>
        </b-card>

        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="gradient-danger"
              size="sm"
              class="float-right"
            >
              Salir <feather-icon
                icon="LogInIcon"
                size="19"
              />
            </b-button>
          </div>
        </template>
      </b-card>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="gradient-danger"
            size="sm"
            class="float-right"
            @click="$bvModal.hide('modal_detalles_ingresos_medicamento')"
          >
            Salir <feather-icon
              icon="LogInIcon"
              size="19"
            />
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BTable, BSpinner, BRow, BCol, BPagination, BFormInput, BButton, BBadge, BMedia, BLink,
  VBTooltip,
} from 'bootstrap-vue'
import store from '@/store'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BTable,
    BSpinner,
    vSelect,
    BRow,
    BCol,
    BFormInput,
    // BDropdown,
    // BDropdownItem,
    BPagination,
    BButton,
    BBadge,
    BMedia,
    BLink,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    /* data: {
      type: Array,
      default: () => [],
    }, */
  },
  data() {
    return {
      itemIngreso: {

      },

      idCompra: 0,
      isBusy: false,
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 50],
      sortBy: 'id',
      sortDesc: false,
      // sortDirection: 'desc',
      filter: '',
      filterOn: [],
      listaLotes: [],
      fields_listaLotes: [

        { key: 'nombre_medicamento', label: 'MEDICAMENTO', class: 'text-left' },
        { key: 'cantidad_compra', label: 'CANT. INGRESADA', class: 'text-center' },
        { key: 'cantidad_disponible', label: 'CANT.DISPONIBLE', class: 'text-center' },
        { key: 'fecha_vencimiento', label: 'VENCIMIENTO', class: 'text-center' },
        { key: 'acciones', label: 'acciones', class: 'text-center' },
      ],
    }
  },
  created() {
    this.$parent.$on('cargarDetallesCompra', this.cargarDetallesCompra)
  },
  methods: {
    actualizarTabla() {
      this.$refs.refTablaLotesDeCompra.refresh()
    },
    cargarDetallesCompra(item) {
      /* this.medCargado = item
      item.lotes_disponibles.forEach((value, index) => {
        this.medCargado.lotes_disponibles[index].fecha_venc_dias = this.fecha_venc_en_dias(value.fecha_vencimiento)
      })
      this.listaLotes = this.medCargado.lotes_disponibles */
      // this.myProvider(ctx, idCompra)
      this.itemIngreso = item
      this.idCompra = item.id
      // this.listaLotes = item.lotes
    },
    async myProvider(ctx) {
      // console.log('CTX: ', ctx)
      // const promise = store.dispatch('ingresos-list-store/getCompraLote', { queryParams: ctx })
      const promise = this.$http.get(`/servicio-salud/farmacia/compra/${this.idCompra}/lote`, { params: ctx })

      // Must return a promise that resolves to an array of items
      return promise.then(response => {
        // console.log('responseGet-lotes compra: ', response)
        const {
          data, total, from, to,
        } = response.data
        // Pluck the array of items off our axios response
        const items = data
        this.totalRows = total || 0
        // this.from = meta.pagination.count
        this.from = from || 0
        this.to = to || 0
        /*
        items.forEach((value, index) => {
          items[index].fecha_venc_dias = this.fecha_menor_en_dias(value.lotes_disponibles)
        }) */

        // Must return an array of items or an empty array if an error occurred
        return items || []
      })
    },
    eliminarLote(item) {
      // console.log('lote a eliminar: ', item)

      this.$swal({
        title: '¿Estas seguro?',
        text: '¡No podrás revertir esto!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar producto.',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const params = {
            idCompra: item.compra_id,
            idLote: item.id, // id = id del lote
          }
          store.dispatch('ingresos-list-store/deleteLoteDeCompra', { params }).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Éxito!',
                icon: 'BellIcon',
                text: 'Producto eliminado como ingreso',
                variant: 'success',
              },
            })
            this.actualizarTabla()
          }).catch(error => {
            console.log(error)
          })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'El prodcuto se mantiene :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
  /* setup() {
    const RECETA_STORE_MODULE_NAME = 'receta-module'

    // Register module
    if (!store.hasModule(RECETA_STORE_MODULE_NAME)) store.registerModule(RECETA_STORE_MODULE_NAME, atencionRecetaStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RECETA_STORE_MODULE_NAME)) store.unregisterModule(RECETA_STORE_MODULE_NAME)
    })

    onMounted(() => {
    })
    return {

    }
  }, */
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
