import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getIngresosMedicamentos(ctx, { queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/servicio-salud/farmacia/compra', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getCompraLote(ctx, { queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicio-salud/farmacia/compra/${queryParams.idCompra}/lote`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteLoteDeCompra(ctx, { params }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/servicio-salud/farmacia/compra/${params.idCompra}/lote/${params.idLote}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
